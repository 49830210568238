<template>
    <div class="">
        <div class="text-center d-flex justify-content-center"> 
          <strong class="mb-2" style="width:500px;font-size:16px" v-html="data.title"></strong>
        </div>
        <div class="d-flex flex-row px-1 justify-content-center" v-if="data.type==='horizontal' || !data.type " >
            <div class="box3 d-flex align-items-center justify-content-center" v-for="(color,index) in data.color" :key="index+'legend'" :style="{'background-color':color,'font-size': '15px', 'border': '1px dotted '}" v-html="data.text[index]"></div>
            
        </div>  
        <div class="" style="max-width:350px" v-if="data.type==='vertical'" >
          <div class="d-flex" v-for="(color,index) in data.color" :key="index+'legend_vertical'">
            <div class="box3 " :style="{'background-color':color,'font-size': '15px', 'border': '1px dotted '}"></div>
            <div class="px-1" style="font-size: 11px" v-html="data.text[index]"></div>
          </div>
        </div>
    </div>
</template>


<script>
export default {
  props: {
    data: Object
  },
data(){
    return{
        legend_data_value:null,
        legend_data_col:null,
        title:null
    }
},
  created(){
    console.log(this.data)
    this.legend_data_color=this.data.color
    this.legend_data_value=this.data.value
    this.title=this.data.title
  }
}

</script>

<style >
.box3 {
    
    padding:8px
    /* height: 22px; */
}
@media screen and (max-width: 730px) {
  .box3 {
    font-size: 14px!important;
    width: 40px;
    /* height: 22px; */
}
}
</style>