<template>
 <div>
  
  <b-overlay :show="(checked_stations && !all_data.length) || (load_concentrations && checked_concentrations)" rounded="sm" z-index="1001">
   
</b-overlay>
  <div style="position:absolute;bottom:0;left:0;z-index: 1001;" class="bottomleft-control">
    <div class="p-2 logo-perifereia" >
      <img
        src="/img/perifereia_attica_logo.png"
        style="width:100%"
      />
     
        
    </div>
  </div>
  
    <div  class="chart-div" :style="{visibility:showChart}" style="position:absolute;bottom:0;right:0;z-index: 9001; background: #6c757d;">
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="danger" style="padding: 0.7rem!important;" @click="showChart='hidden'">x</b-button>
      </div>
      
      <vue-highcharts :options="options" ref="lineCharts"></vue-highcharts>
    
      
    </div>
    <div  class="chart-div" :style="{visibility:showChartBar}" style="position:absolute;bottom:0;right:0;z-index: 9001; background: #6c757d;width:100%">
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="danger" style="padding: 0.7rem!important;" @click="showChartBar='hidden'">x</b-button>
      </div>
      <b-form-select v-model="selected_gust" class="w-25 ml-5" @change="changeGust">
        <!-- This slot appears above the options from 'options' prop -->
        <template #first>
          <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
        </template>

        <!-- These options will appear after the ones from 'options' prop -->
        <b-form-select-option value="pm25">PM2.5</b-form-select-option>
        <b-form-select-option value="pm10">PM10</b-form-select-option>
        <b-form-select-option value="no2">NO2</b-form-select-option>
        <b-form-select-option value="o3">O3</b-form-select-option>
      </b-form-select>
      <div>
        <vue-highcharts :options="optionsBar" ref="lineChartsBar"></vue-highcharts>
      </div>
      
    </div>
   

    <div style="position:absolute;top:80px;z-index:1001"> 
      <b-button  squared class="btn btn-color-sidebar btn-settings" v-b-toggle.sidebar-2 ><i class="fa-solid fa-layer-group"></i></b-button>
    </div>
    <b-sidebar  left title="ΠεΣΠΚΑ"  v-model="sidebar_settings" @change="setsidebarMap" id="sidebar-2" width="400px" bg-variant="secondary" text-variant="light">
      <!--<div class="px-2 py-1 d-flex align-items-center">
       
       <p class="mr-auto" style="font-size:17px">Πλατφόρμα διαχείρισης και οπτικοποίησης δεδομένων του ΠΠΚΑ</p>
       <div class="px-1 d-flex align-items-center">
         <p class="text-center px-3 rounded-pill bg-danger " style="font-size:13px;margin-bottom: 0rem!important;">Beta</p>
       </div>
      
      </div>-->
      
      <template #header="{hide}">
        
        <div class="d-flex w-100 justify-content-between align-items-center"> 
          <p style="font-size: 1px;">`</p>
          <img src="/img/perifereia_attica_logo.png" style="width:35%"/>
       <!--<div class="px-2 py-1 d-flex align-items-center">
       
        <p class="mr-auto" style="font-size:17px">Πλατφόρμα διαχείρισης και οπτικοποίησης δεδομένων του ΠΠΚΑ</p>
        <div class="px-1 d-flex align-items-center">
          <p class="text-center px-3 rounded-pill bg-danger " style="font-size:13px;margin-bottom: 0rem!important;">Beta</p>
        </div>
       
       </div>-->
       <div @click="hide" style="cursor: pointer;color:red;font-size: 20px;">x</div>
      </div>
      </template>
      

      <div class="" v-if="data_tree_json && tree_json_multiple && tree_json_info">

        <v-jstree
            :data="data_tree_json"
            show-checkbox
            :multiple="false"
            
            whole-row
      
            @item-click="itemClick"
            :draggable="false"
          >
          <template slot-scope="_">
          <div style="display: inherit;"  @click.ctrl="customItemClickWithCtrl">
            
    
            <i v-if="_.model.downloaddata" v-b-tooltip.html :title="'Download data'" class="fa-solid fa-download"  role="presentation"  @click.stop="DownloadMapData(_.model)"></i>
            <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
            <span v-b-tooltip.html  :title=" _.model.description" v-html=" _.model.text + ' '"></span>
          
            
            <i v-b-tooltip.html :title="'Add to multipleMap'" class="fa-solid fa-circle-plus"  role="presentation" v-if="_.model.multipleMap" @click.stop="AddToMultipleMap(_.model)"></i>
            <span v-if="secondMapData.length">
              
              <i v-if="secondMapData[0].item_all.data_column=== _.model.data_column" class="fa-solid fa-check px-2" style="color: orange"></i>

            </span>
            
          </div>
        </template>
          </v-jstree>
  
        <v-jstree
            :data="tree_json_multiple"
            show-checkbox
            :multiple="true"
            allow-batch
            whole-row
            @item-click="itemClick"
            :draggable="false"
          >
          <template slot-scope="_">
          <div style="display: inherit;"  @click.ctrl="customItemClickWithCtrl">
            
    
            <i v-if="_.model.downloaddata" v-b-tooltip.html :title="'Download data'" class="fa-solid fa-download"  role="presentation"  @click.stop="DownloadMapData(_.model)"></i>
            <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
            <span v-b-tooltip.html  :title=" _.model.description" v-html=" _.model.text + ' '"></span>
          
            
            <i v-b-tooltip.html :title="'Add to multipleMap'" class="fa-solid fa-circle-plus"  role="presentation" v-if="_.model.multipleMap" @click.stop="AddToMultipleMap(_.model)"></i>
            <span v-if="secondMapData.length">
              
              <i v-if="secondMapData[0].item_all.data_column=== _.model.data_column" class="fa-solid fa-check px-2" style="color: orange"></i>

            </span>
            
          </div>
        </template>
        </v-jstree>
        <v-jstree
            :data="tree_json_info"
            show-checkbox
            :multiple="true"
            allow-batch
            whole-row
            @item-click="itemClick"
            :draggable="false"
          >
          <template slot-scope="_">
          <div style="display: inherit;"  @click.ctrl="customItemClickWithCtrl">
            
    
            <i v-if="_.model.downloaddata" v-b-tooltip.html :title="'Download data'" class="fa-solid fa-download"  role="presentation"  @click.stop="DownloadMapData(_.model)"></i>
            <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
            <span v-b-tooltip.html  :title=" _.model.description" v-html=" _.model.text + ' '"></span>
          
            
            <i v-b-tooltip.html :title="'Add to multipleMap'" class="fa-solid fa-circle-plus"  role="presentation" v-if="_.model.multipleMap" @click.stop="AddToMultipleMap(_.model)"></i>
            <span v-if="secondMapData.length">
              
              <i v-if="secondMapData[0].item_all.data_column=== _.model.data_column" class="fa-solid fa-check px-2" style="color: orange"></i>

            </span>
            
          </div>
        </template>
        </v-jstree>
        <!--<b-form-checkbox v-model="checked_stations" name="check-button" switch>
          Stations <i class="fa-solid fa-tower-broadcast"></i> 
       </b-form-checkbox>-->
      </div>
      

      <div class="p-2  text-center">
        <!--<b-form-checkbox v-model="checked_concentrations" name="check-button" class="mb-2" switch>
          Concentrations 
       </b-form-checkbox>
       <b-form-select  v-model="selected_year" class="align-self-center" size="sm"  @change="changeYear">
                                
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Select Year--</b-form-select-option>
                                </template>
                              
                    <b-form-select-option :value="1995">1995</b-form-select-option>
                    <b-form-select-option :value="2046" >2046</b-form-select-option>
                    <b-form-select-option :value="2096">2096</b-form-select-option>
                   
                   
        </b-form-select>
        <b-form-select  v-model="selected_grid" class="align-self-center mt-2 " size="sm"  @change="changeGrid">
                                
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Select Grid--</b-form-select-option>
                                </template>
                              
                    <b-form-select-option value="pm25">PM2.5</b-form-select-option>
                    <b-form-select-option value="no2" html-field="html">NO2</b-form-select-option>
                    <b-form-select-option value="o3" html-field="html">O3</b-form-select-option>
                    <b-form-select-option value="pm10" html-field="html">PM10</b-form-select-option>
                    
                   
        </b-form-select>
        
        <b-form-select  v-model="selected_type" class="align-self-center mt-2 mb-3" size="sm" @change="changeType">             
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Select Type--</b-form-select-option>
                                </template>
                              
                    <b-form-select-option value="yearly">Annual</b-form-select-option>
                    <b-form-select-option value="monthly">Monthly</b-form-select-option>      
        </b-form-select>
        <div class="w-100 d-flex justify-content-center" v-if="monthly_selected.length">
        <vue-slider
          class="mt-2"
          style="height:20vh"
           v-model="selected_month"
          :data="layer_months_Greece"
          direction="ttb"   
          @change=" changeSlider"              
        ></vue-slider>  
        </div> -->
      </div>

      <template v-slot:footer>
           <!-- <div class="d-flex justify-content-center pb-3 mt-3 bg-white">
              <img src="/img/perifereia_attica_logo.png" style="width:25%"/>
            </div>-->
            
            <div class="row justify-content-center align-items-center bg-white m-2 p-2 shadow" style="margin-right: 0px;margin-left: 0px;border-radius: 10px;">
              <div class="col-12 text-dark text-center">Ανάπτυξη εφαρμογής</div>
              <div class="col-4">
                <a href="https://www.adens.gr/" target="_blank"><img src="/img/logo_adens.png" style="width:100%"/></a>
              </div>
              <div class="col-4">
                <a href="http://apcg.meteo.noa.gr/" target="_blank"><img src="/img/apcg-logo.png" style="width:100%"/></a>
              </div>
              <div class="col-4">
                <a href="https://idcom.gr" target="_blank"><img src="/img/idcom-fav.png" style="width:100%"/></a>
              </div>
            </div>
            <div class="m-2">
              <img src="../../public/img/footer-768x115.png" style="border-radius: 10px;width:100%" />
            </div>

      </template>

  
    </b-sidebar>
    <div class="shadow" v-if="monthly_selected.length" style="position:absolute;top:80px;left:420px;z-index:2000; background:#fff;border-radius:20px;padding:40px">
      <vue-slider
          class="mr-4"
          style="height:30vh"
           v-model="selected_month"
          :data="layer_months_Greece"
          direction="ttb"   
          @change=" changeSlider"              
        ></vue-slider> 
        
    </div>
    
    <rs-panes split-to="columns" :allow-resize="true" :size="screenWidth" style="height:94%">
      <div slot="firstPane" >
        <l-map style="height:94vh" :options="{attributionControl: false, zoomControl: false}" :zoom="zoom" :center="center" ref="map1">
    <!--<l-tile-layer :url="url"></l-tile-layer>-->
    <l-control-layers position="topright"></l-control-layers>
    <l-tile-layer
                  v-for="tileProvider in tileProviders"
                  :key="tileProvider.name"
                  :name="tileProvider.name"
                  :visible="tileProvider.visible"
                  :url="tileProvider.url"
                  :attribution="tileProvider.attribution"
                  layer-type="base"
                />
    <v-geosearch :options="geosearchOptions"></v-geosearch>
    <v-locatecontrol :options="{position:'topright'}"/>
    <l-control-polyline-measure
      :options="{
        showUnitControl: true,
        showClearControl: true,
        clearMeasurementsOnStop: false,
        }"
       position="topright"
      />
      <l-control-scale
                  position="topright"
                  :imperial="true"
                  :metric="true"
                ></l-control-scale>
    <!--<l-wms-tile-layer
                    :base-url="'https://geoserver.idcom.gr/geoserver/perifereia/wms'"
                    :layers="'perifereia:geodata_dimoi_attikis_kallikratis'"
                    :transparent="true"
                    format="image/png"
                    :zIndex="7"
                    :opacity="1"
                  >
    </l-wms-tile-layer>-->
   
    <div v-for="(all_data_pp,index) in all_data" :key="index+'stationss'">
      <div v-if="all_data_pp.selected">
        <div v-if="all_data_pp.type==='point'">
          <div v-for="(station,ind) in all_data_pp.data" :key="ind+'stations'"> 
            <l-marker :lat-lng="[station.latitude,station.longitude]"  @click="onclickStation(station,all_data_pp.name)">
              
              <l-icon>
              <div style="">

                <i class="fa-solid fa-location-pin" :style="{'font-size': '35px','color':all_data_pp.all_info.icon_color}" ></i>
                <i :class="all_data_pp.all_info.icon" style="position:absolute;top:11px;left:6.6px;font-size: 12px;color:#fff;"></i>
              </div>
              </l-icon>
              <l-popup v-if="station.site_name">{{station.site_name}}</l-popup>
              <l-popup v-if="station.name">{{station.name}}</l-popup>
            </l-marker>
          </div>
        </div>
        <div v-if="all_data_pp.type==='geojson'">
          
            <div v-for="(no2Data,index) in all_data_pp.data" :key="index+'stations'">
              <div v-if="all_data_pp.legend.tooltip">
              <l-geo-json :geojson="{
                                type: 'FeatureCollection',
                                features: [
                                  {
                                    type: 'Feature',
                                    geometry: JSON.parse(no2Data.st_asgeojson),
                                    properties: [all_data_pp.legend,no2Data],
                                  },
                                ],
                              }"
                          :optionsStyle="colorsFunc(no2Data[all_data_pp.legend.column],all_data_pp.legend)"
                          :options="optionsGeoJson"
                              
              ></l-geo-json>
              <!--
                 properties: [all_data_pp.legend,no2Data],
                :optionsStyle="colorsFunc(no2Data[all_data_pp.legend.column],all_data_pp.legend)"
                              :options="optionsGeoJson"-->
            </div>
            <div v-else>
              <l-geo-json :geojson="{
                                type: 'FeatureCollection',
                                features: [
                                  {
                                    type: 'Feature',
                                    geometry: JSON.parse(no2Data.st_asgeojson),
                                    //properties: [all_data_pp.legend,no2Data],
                                  },
                                ],
                              }"
                              :optionsStyle="colorsFunc(no2Data[all_data_pp.legend.column],all_data_pp.legend)"
                              
              ></l-geo-json>
            </div>
            </div>

        </div>
        <div v-if="all_data_pp.type==='geoserver'">
          <l-wms-tile-layer
                        ref="wms_layer"
                        :key="all_data_pp.data_column"
                        :base-url="
                          all_data_pp.value.url + '?viewparams=' + all_data_pp.value.params
                        "
                        :layers="all_data_pp.value.api"
                        :visible="all_data_pp.selected"
                        :transparent="true"
                        format="image/png"
                        :zIndex="12"
                        :opacity="0.9"
                      >
            </l-wms-tile-layer>
        </div>
        <div v-if="all_data_pp.type==='png'">
          <l-image-overlay
            :url="all_data_pp.value.api"
            :bounds="all_data_pp.value.params.bounds"
            :visible="all_data_pp.selected"
          />
        </div>
    </div>
    </div>
   <!-- <div v-if="all_data['stationsAllData']['data'] && checked_stations">
    <div v-for="(station,index) in stationsAllData" :key="index+'stations'">
      <l-marker :lat-lng="[station.Latitude,station.Longitude]"  @click="onclickStation(station)">
        <l-icon
          :icon-size="[40, 40]"
          icon-url="/img/pin2.png"
        />
        <l-popup>{{station.Name}}</l-popup>
      </l-marker>
    </div>
    </div>-->
    <div v-if="no2AllData && checked_concentrations">
      <div v-for="(no2Data,index) in no2AllData" :key="index+'stations'">
        <l-geo-json :geojson="{
                          type: 'FeatureCollection',
                          features: [
                            {
                              type: 'Feature',
                              geometry: JSON.parse(no2Data.st_asgeojson),
                              properties: {},
                            },
                          ],
                        }"
                        :optionsStyle="colorsFunc(no2Data.dn)"
                    ></l-geo-json>
      </div>
      
    </div>
    <div v-if="markerActive && checked_stations">
    <l-marker :lat-lng="markerActive" >
        <l-icon
          :icon-size="[40, 40]"
          icon-url="/img/pin.png"
        />
    </l-marker>
    </div>
    <!--<l-image-overlay
            :url="'http://localhost:8080/png/RCP4.5-2040-Mean_tr.png'"
            :bounds="[[38.342,24.12],[35.99,22.888]]"
            :opacity="1"
          />-->
    
  </l-map>


  <div class="legend-div" style="" v-if="current_legend">
      <div class="card shadow" style="border-radius: 20px;">
        <div class="card-body">
          <legendControl :data="current_legend"></legendControl>        
        </div>
      </div>
    </div>
   


      </div>
      <div slot="secondPane">
        <l-map style="height:94vh" :zoom="zoom" :center="center" ref="map2"  :options="{attributionControl: false}">
    <l-tile-layer :url="url" ></l-tile-layer>
   
    <div v-for="(item,index) in secondMapData" :key="index+'stationsss'">
    <div v-if="item.type_of_data==='point'">
          <div v-for="(station,ind) in item.data" :key="ind+'stationsw'"> 
            <l-marker :lat-lng="[station.Latitude,station.Longitude]"  @click="onclickStation(station)">
              <l-icon
                :icon-size="[40, 40]"
                icon-url="/img/pin2.png"
              />
              <l-popup>{{station.site_name}}</l-popup>
            </l-marker>
          </div>
        </div>
        <div v-if="item.type_of_data==='geojson'">
          <!--:options="optionsGeoJson"-->
            <div v-for="(no2Data,index) in item.data" :key="index+'stationsq'">
              <div v-if="item.legend.tooltip">
              <l-geo-json :geojson="{
                                type: 'FeatureCollection',
                                features: [
                                  {
                                    type: 'Feature',
                                    geometry: JSON.parse(no2Data.st_asgeojson),
                                    properties: [item.legend,no2Data],
                                  },
                                ],
                              }"
                              :optionsStyle="colorsFunc(no2Data[item.legend.column],item.legend)"
                              :options="optionsGeoJson"
              ></l-geo-json>
            </div>
            <div v-else>
              <l-geo-json :geojson="{
                                type: 'FeatureCollection',
                                features: [
                                  {
                                    type: 'Feature',
                                    geometry: JSON.parse(no2Data.st_asgeojson),
                                    //properties: [all_data_pp.legend,no2Data],
                                  },
                                ],
                              }"
                              :optionsStyle="colorsFunc(no2Data[item.legend.column],item.legend)"
                              
              ></l-geo-json>
            </div>
            </div>
           

        </div>
        <div v-if="item.type_of_data==='geoserver'">
          <l-wms-tile-layer
                        ref="wms_layer"
                        :key="item.item_all.data_column"
                        :base-url="
                          item.item_all.value[0].url + '?viewparams=' + item.item_all.value[0].params
                        "
                        :layers="item.item_all.value[0].api"
                        :visible="true"
                        :transparent="true"
                        format="image/png"
                        :zIndex="12"
                        :opacity="0.9"
                      >
            </l-wms-tile-layer>
        </div>
        </div>
        

  </l-map>
    <div class="legend-div" style="" v-if="current_legend_map2">
      <div class="card shadow" style="border-radius: 20px;">
        <div class="card-body">
          <legendControl :data="current_legend_map2"></legendControl>        
        </div>
      </div>
    </div>
    <div style="position:absolute;top:0;right:0;z-index:1001;cursor:pointer" @click="closeMap2()">
      <div class="p-3 bg-danger text-light rounded mt-4"> 
        x
      </div>

    </div>
      </div>
    </rs-panes>
    
 </div>
</template>


<script>
import L from "leaflet";
import axios_django from "../axios-auth";
//import axios_all from "axios";
import VJstree from "vue-jstree";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import axios from 'axios'
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
import 'vue-slider-component/dist-css/vue-slider-component.css'
import 'vue-slider-component/theme/default.css'
import legend_data from '../../public/json/legend_data.json'
import legendControl from './legendBari.vue'
import VGeosearch from 'vue2-leaflet-geosearch';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Vue2LeafletLocatecontrol from 'vue2-leaflet-locatecontrol'
import ResSplitPane from 'vue-resize-split-pane'
import "../js/L.Map.Sync.js";
import "../js/leaflet-polygon.fillPattern.js";

export default {
  components: {
    VueSlider,
    VJstree,
    'rs-panes':ResSplitPane,
    legendControl,
    "l-control-polyline-measure": LControlPolylineMeasure,
    VGeosearch,
    'v-locatecontrol': Vue2LeafletLocatecontrol
  },
  data() {
    return {
      chartDataBar:[],
      years:[],
      months:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
      selected_gust:null,
      firstTime:true,
      secondMapData:[],
      map_width:12,
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        style: 'button',
        position:'topright'
      },
      height:screen.availHeight ,
      width:screen.width,
      screenWidth:screen.width,
      all_data:[],
      monthly_selected:[],
      preload_data_all:[],
      data_tree_json:null,
      tree_json_multiple:null,
      tree_json_info:null,
      load_concentrations:false,
      show_loader:false,
      checked_concentrations:false,
      legend_data:legend_data,
      current_legend:null,
      current_legend_map2:null,
      checked_stations:false,
      selected_year:null,
      selected_month:'1',
      layer_months_Greece:{
            "1":"Jan.",
            "2":"Feb.",
            "3":"Mar.",
            "4":"Apr.",
            "5":"May",
            "6":"Jun.",
            "7":"Jul.",
            "8":"Aug.",
            "9":"Sep.",
            "10":"Oct.",
            "11":"Nov.",
            "12":"Dec."
            
        },
      selected_grid:null,
      selected_type:null,
      sidebar_settings:this.$store.state.sidebarMap,
      no2AllData:null,
      markerActive:null,
      showChart:'hidden',
      showChartBar:'hidden',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      stationsAllData:null,
      zoom: 10,
      center: [37.983810, 23.727539],
      options:{
        chart: {
          height: 300,
          type: 'spline',
          backgroundColor: 'transparent',
          
        },
        title: {
            text: 'PM2.5'
        },


        yAxis: {
            title: {
                text: 'Monthly concentration (μgm<sup>-3</sup>)',
                useHTML: true,
                style: {
                  color: 'white'
                }
            },
            labels: {
            style: {
                color: 'white'
            }
          }
        },

        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
            style: {
                color: 'white'
            }
          }
            
        },


        

        series: [],
      },
      optionsBar:{
        chart: {
          renderTo: 'container',
          height: 250,
          type: 'column',
          backgroundColor: 'transparent',
         
        },
       

        title: {
            text: ' '
        },


        yAxis: {
          
            title: {
                text: 'Monthly concentration (μgm<sup>-3</sup>)',
                useHTML: true,
                style: {
                  color: 'white'
                }
            },
            labels: {
            style: {
                color: 'white'
            }
          }
        },

        xAxis: {
          categories: [],
           
          labels: {
            style: {
                color: 'white'
            }
          },
          
        },


        

        series: [],

        
      },
      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "OpenStreetMap.HOT",
          visible: false,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
          url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
        },

        {
          name: "OpenTopoMap",
          visible: false,
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: "Stadia.AlidadeSmoothDark",
          visible: false,
          url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=9a9206be-79a9-461b-986f-f9b419cdb7ea",
          attribution:
            '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        },
        {
          name: "CyclOSM",
          visible: false,
          url: "https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
          attribution:
            '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
        {
          name: "Esri.WorldImagery",
          visible: false,
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        },
      ],
    }
  },
  computed: {
    optionsGeoJson() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    onEachFeatureFunction() {
      //var v = this;
      return (feature, layer) => {
        //console.log(feature.properties.legend.column)
        //console.log(feature.properties.data)
        //console.log( feature.properties.data[feature.properties.legend.column])
      
        if(feature.properties[0].tooltip){
          var tooltip_column
          
          if(typeof feature.properties[1][feature.properties[0].tooltip.tooltip_column] === 'number'){
            tooltip_column=Math.round(feature.properties[1][feature.properties[0].tooltip.tooltip_column])
            layer.bindTooltip(
            "<div style='opacity:1!important;'>" +
              tooltip_column +
              "</div>",
            { permanent: feature.properties[0].tooltip.permanent, sticky: true,opacity:0.9,className: 'leaflet-tooltip-own' }
            );
          }else{
            var cols=feature.properties[0].tooltip.tooltip_column.split(',')
            var descr=''
            for(var gg in cols){
              descr=descr+feature.properties[1][cols[gg]]+'<br>'
            }
            //tooltip_column=feature.properties[1][feature.properties[0].tooltip.tooltip_column]
            var value
            if(!feature.properties[0].tooltip.round_disabled){
              value=Math.round(feature.properties[1][feature.properties[0].column])
            }else{
              value=feature.properties[1][feature.properties[0].column]
            }
            //console.log(typeof value)
            value=value.toString()
            var hh=value!== 'NaN' ? '<br>value:'+value : '<div></div>'
            layer.bindTooltip(
            "<div style='opacity:1!important;'>" +
              descr + hh+
              "</div>",
            { permanent: feature.properties[0].tooltip.permanent, sticky: feature.properties[0].tooltip.tooltip_sticky===false?false:true,opacity:0.9,className: feature.properties[0].tooltip.tooltip_classname===undefined ?'leaflet-tooltip-own':feature.properties[0].tooltip.tooltip_classname }
          );
          }


          //console.log(feature.properties)
          if(feature.properties[0].popup){

            if(feature.properties[0].popup.show){
              layer.bindPopup(
                `<div>Δήμος:`+feature.properties[1].lektiko+`</div><hr>`+
                `<div>Email:`+feature.properties[1].email+`</div><hr>`+
                `<div>Website:`+feature.properties[1].website+`</div><hr>`+
                `<div>Μόνιμος Πληθυσμός:`+feature.properties[1].monimos_plithismos+`</div><hr>`+
                `<div>Δίκτυα κα:`+feature.properties[1].diktia_ka+`</div><hr>`+
                `<div>ΣΔ Στρατηγικές:`+feature.properties[1].sd_stratigikes+`</div>`
              )
            }
          }
          //"highlight_polygon":{"hover":true}
          //console.log(feature.properties[0])
          if(feature.properties[0].highlight_polygon){
            if(feature.properties[0].highlight_polygon.hover){
              layer.on('mouseover', function() {
                  this.setStyle({
                      color: 'red'   //or whatever style you wish to use;
                  });
              });
              layer.on('mouseout', function() {
                  this.setStyle({color:'blue'})
              });
            }
          }
        
          
        }
        if(feature.geometry.type==='Point'){
          var coww={'a':'#030050','b':'#5a007e','c':'#0039d6','other':'#0099ff'}
          var str_html=feature.properties[0].marker_html.replace("color_marker",coww[feature.properties[1].priority]);
          const customIcon = L.divIcon({
            className: 'custom-icon',
            html: str_html,
          });
//<i class="all_data_pp.all_info.icon" style="position:absolute;top:11px;left:6.6px;font-size: 12px;color:#fff;"></i>
          layer.setIcon(customIcon);

        }
       
       
      }
    }
  },
  mounted(){
    
    this.$nextTick(() => {
  
      //this.$refs.map1.mapObject.sync(this.$refs.map2.mapObject)
      //this.$refs.map2.mapObject.sync(this.$refs.map1.mapObject)
    });
  },
  async created(){
    //var v=this
    //this.$refs.map1.mapObject
   
   
    //this.$refs.map1.sync(this.$refs.map2)
    this.data_tree_json=await axios.get('/json/tree_json.json')
    this.data_tree_json=this.data_tree_json.data
    this.tree_json_multiple=await axios.get('/json/tree_json_multiple.json')
    this.tree_json_multiple=this.tree_json_multiple.data
    this.tree_json_info=await axios.get('/json/tree_json_info.json')
    this.tree_json_info=this.tree_json_info.data

    
    this.preload_data_all=await axios.get('/json/preload_data.json')
    this.preload_data_all=this.preload_data_all.data
    var data
    for(var rr=0; rr<this.preload_data_all.length; rr++){
     
      if(this.preload_data_all[rr].value.api.includes('http')){
        data=await axios.get(this.preload_data_all[rr].value.api)
      }else{
        data=await axios_django.get(this.preload_data_all[rr].value.api,{params:this.preload_data_all[rr].value.params})

      }
      
      this.all_data.push({name:this.preload_data_all[rr].data_column,data:data.data,selected:false,type:this.preload_data_all[rr].type_of_data,all_info:this.preload_data_all[rr]})
      console.log(this.all_data)
      //this.transformDataStations(this.all_data.length-1)
    }
/*
    axios.get('http://localhost/teaser/pespka.php').then(res=>{
      console.log(res)
      v.no2AllData=res.data

    })
    {
        "type_of_data":"point",
        "value":{"api":"https://as2.panacea-ri.gr/sensors/monthlydata/all","params": null}
    },
*/
  },
  methods:{
    closeMap2(){
      this.screenWidth=this.width
      this.secondMapData=[]
      setTimeout(() => {
        this.$refs.map1.mapObject.invalidateSize();

      },200)
    },
    customItemClickWithCtrl: function () {
                console.log('click + ctrl')
    },
    DownloadMapData(item){
      console.log(item)
      if(item.type_of_data==='geojson' || item.type_of_data==='point'){

        const value=item.value[0]
        
        axios_django.get(value.api,{params:value.params}).then(res=>{
            var geojson={}
            var geometry
            console.log(res.data)
            geojson["type"]= "FeatureCollection"
              for(var xx in res.data){
              
               //res.data[xx].geometry= JSON.parse(res.data[xx].st_asgeojson)
               delete res.data[xx].geom;
               
               delete res.data[xx].descriptio;
               delete res.data[xx].path;
               if(!geojson['features']){
                geojson['features']=[]
               }
               if(res.data[xx].st_asgeojson){
                geometry=JSON.parse(res.data[xx].st_asgeojson)
               }else{
                geometry= {"type": "Point", "coordinates": [res.data[xx].longitude,res.data[xx].latitude]}
               }
               geojson['features'].push( { 
                "type": "Feature",
                "geometry": geometry,
                "properties":res.data[xx]
                })
              
               // delete res.data[xx].st_asgeojson;

              }
              
             
              console.log('download data:',geojson)
              // Convert JSON to a string
              const jsonString = JSON.stringify(geojson, null, 2);

              // Create a Blob object with the JSON data
              const blob = new Blob([jsonString], { type: "application/json" });

              // Create a URL for the Blob
              const blobURL = URL.createObjectURL(blob);

              // Create a download link
              const a = document.createElement("a");
              a.href = blobURL;
              a.download = item.data_column+'.json'//"data.json"; // The filename for the downloaded file

              // Append the link to the DOM and trigger the download
              document.body.appendChild(a);
              a.click();

              // Clean up: remove the temporary URL
              URL.revokeObjectURL(blobURL);
            })
      }
      if(item.type_of_data==='geoserver'){
      
        // Define the base URL of your GeoServer
        const geoServerBaseUrl = item.geoserverurl
        window.open(geoServerBaseUrl, '_blank');
      
      }
    },
    AddToMultipleMap(item){
      //console.log(item)
     // this.secondMapData={item
      
      this.secondMapData=[]
      var v=this
      this.map_width=7
      this.screenWidth=this.width/1.7
      if(item.type_of_data==='geojson'){

      
      item.value[0].params.month=this.selected_month
      axios_django.get(item.value[0].api,{params:item.value[0].params}).then(res=>{
        v.secondMapData.push({type_of_data:item.type_of_data,legend:item.legend,item_all:item})
        v.secondMapData[0].data=res.data
        console.log(v.secondMapData)
        //console.log(v.$refs.map1)
        //console.log(v.$refs.map2)

        
        setTimeout(() => {
          if(this.firstTime){
            v.$refs.map1.mapObject.sync(v.$refs.map2.mapObject)
            v.$refs.map2.mapObject.sync(v.$refs.map1.mapObject)
          }
         
          v.$refs.map1.mapObject.invalidateSize();
          v.$refs.map2.mapObject.invalidateSize();
          v.firstTime=false
        }, 200);
        
        if(item.legend){
          this.current_legend_map2=item.legend
        }
        
      })
    }
    if(item.type_of_data==='geoserver'){
      this.secondMapData.push({type_of_data:item.type_of_data,legend:item.legend,item_all:item})
      setTimeout(() => {
          if(this.firstTime){
            v.$refs.map1.mapObject.sync(v.$refs.map2.mapObject)
            v.$refs.map2.mapObject.sync(v.$refs.map1.mapObject)
          }
         
          v.$refs.map1.mapObject.invalidateSize();
          v.$refs.map2.mapObject.invalidateSize();
          v.firstTime=false
        }, 200);
        
        this.current_legend_map2=item.legend
        
    }
console.log(this.secondMapData)
      //this.secondMapData.data
    },
    async itemClick(node) {

      if(!node.model.multiple){
         
        console.log(node.model)
          
          for(var aa=0; aa<this.all_data.length; aa++){
            console.log('here',this.all_data[aa])
            if(this.all_data[aa].selected && this.all_data[aa].name===node.model.data_column){
              console.log('name:',this.all_data[aa].name)
              node.model.selected=!node.model.selected
              //this.current_legend=null
            }
            if(!this.all_data[aa].all_info.multiple){
              this.all_data[aa].selected=false
            }
          
          }
          this.monthly_selected=[]
      }
    
      
      
      if (node.model.children.length > 0) {
        for (var i = 0; i < node.model.children.length; i++) {
          if (node.model.children[i].children.length > 0) {
            // console.log(node.model.children[i].children.length)
            for (var pp = 0;pp < node.model.children[i].children.length;pp++) {
              
              for(var xx=0;xx<node.model.children[i].children[pp].value.length;xx++){

              if(node.model.children[i].children[pp].value[xx]){
                
                this.create_Layer_Flood_Function(
                node.model.children[i].children[pp].value[xx],
                node.model.selected,
                node.model.children[i].children[pp].type_of_data,
                node.model.children[i].children[pp].loaded,
                node.model.children[i].children[pp].data_column,
                node.model.children[i].children[pp].legend,
                node.model.children[i].children[pp]
                );
                
                if(node.model.children[i].children[pp].value[xx].params.type!=='monthly'){
                  node.model.children[i].children[pp].loaded=true
                }
              }
              
              }
              //console.log(this.layers_flood)
            }
          } else {
            if(node.model.children[i].value){
                for(var vv=0;vv<node.model.children[i].value.length;vv++){
                  console.log(node.model.children[i].value[vv])
                  if(node.model.children[i].value[vv]){
                    this.create_Layer_Function(
                      node.model.children[i].value[vv],
                      node.model.selected,
                      node.model.children[i].type_of_data,
                      node.model.children[i].loaded,
                      node.model.children[i].data_column,
                      node.model.children[i].legend,
                      node.model.children[i]
                    );
                    if(node.model.children[i].value[vv].params.type!=='monthly'){
                      node.model.children[i].loaded=true
                    }
                  }
                }
            }
          }
        }
      } else {

        for(var qq=0;qq<node.model.value.length;qq++){
         // console.log(this.selected_area_of_interest)
         if(node.model.value[qq]){
          this.create_Layer_Function(
            node.model.value[qq],
            node.model.selected,
            node.model.type_of_data,
            node.model.loaded,
            node.model.data_column,
            node.model.legend,
            node.model
          );
          if(node.model.value[qq].params.type!=='monthly'){
            node.model.loaded=true
          }
          
          }
          
        }
      }


    
    
    },
    async create_Layer_Function(value, selected,type_of_data,loaded,data_column,legend,all_info) {
      //console.log(data_column)
      //if(selected){
        
        if(loaded){
  
          for(var tt=0; tt<this.all_data.length; tt++){
            if(this.all_data[tt].name===data_column){
              this.all_data[tt]['selected']=selected
              if(value.params){

              
              if(!selected){
                if(value.params.type==='monthly'){
                var num =this.monthly_selected.indexOf(tt)
                this.monthly_selected.splice(num, 1);
                }
              }else{
                if(value.params.type==='monthly'){
                this.monthly_selected.push(tt)
                }
              }
            } 
            }
          }
          
        }
        if(type_of_data==='geojson'){
          if(!loaded){
            //console.log('here')
            if(value.params.type==='monthly'){
              value.params.month=this.selected_month
            }
            axios_django.get(value.api,{params:value.params}).then(res=>{
              console.log('no2:',res.data)
              //v.no2AllData=res.data
              //v.load_concentrations=false

              this.all_data.push({name:data_column,data:res.data,selected:selected,type:type_of_data,legend:legend,value:value,all_info:all_info})
              
              if(value.params.type==='monthly'){
                this.monthly_selected.push(this.all_data.length-1)
              }
              
              //v.current_legend=v.legend_data[v.selected_grid]
              //console.log(v.current_legend)
            })
            
          }
        }
        if(type_of_data==='point'){
          if(loaded){
            //console.log(data_column)
            for(var rr=0; rr<this.all_data.length; rr++){
              
              if(this.all_data[rr].name===data_column){
                this.all_data[rr].selected=selected
              }
            }
          }else{
            axios_django.get(value.api,{params:value.params}).then(res=>{
              console.log('point:',res.data)
              this.all_data.push({name:data_column,data:res.data,selected:selected,type:type_of_data,legend:legend,value:value,all_info:all_info})    
            })
          }
          //console.log(this.all_data)
          //this.checked_stations=selected
        }
        if(type_of_data==='geoserver'){
          if(loaded){
            for(var vv=0; vv<this.all_data.length; vv++){
              
              if(this.all_data[vv].name===data_column){
                this.all_data[vv].selected=selected
              }
            }
          }else{
            this.all_data.push({name:data_column,selected:selected,type:type_of_data,legend:legend,value:value,all_info:all_info})

          }
         
        }

        if(type_of_data==='png'){
          if(loaded){
            for(var zx=0; rr<this.all_data.length; zx++){
                
                if(this.all_data[zx].name===data_column){
                  this.all_data[zx].selected=selected
                }
            }
          }else{
            this.all_data.push({name:data_column,value:value,type:type_of_data,selected:selected,all_info:all_info})
          }

          console.log(this.all_data)

        }
        console.log(legend)
        
        if(legend){
          var show_legend = legend.show==false ? false:true 
          if(show_legend){
              this.current_legend=legend
          }

          if(selected===false && show_legend){
          this.current_legend=null

        }
        }else{
          this.current_legend=null
        }
        

       
        
       
        
    },
    setsidebarMap(){
      //console.log(this.sidebar_settings)
      this.$store.commit('set', ['sidebarMap', this.sidebar_settings])
    },
    changeYear(){
      if(this.selected_grid && this.selected_type){
        this.getDataAll()
      }
    },
    changeGrid(){
      console.log(this.selected_grid)
      console.log(this.selected_type)
      console.log(this.selected_month)
      if(this.selected_type){
        this.getDataAll()
      }
    },  
    changeType(){
      console.log(this.selected_grid)
      console.log(this.selected_type)
      console.log(this.selected_month)
      this.getDataAll()
    },  
    async changeSlider(){
     // console.log(this.monthly_selected)
      if(this.secondMapData.length){
        
        this.secondMapData[0].item_all.value[0].params.month=this.selected_month
        //console.log(this.secondMapData)
        //this.secondMapData[0].data=[]
        var data_secondMapData=await axios_django.get(this.secondMapData[0].item_all.value[0].api,{params:this.secondMapData[0].item_all.value[0].params})
       // console.log(data_secondMapData)
        
        this.secondMapData[0].data=data_secondMapData.data



       
       
      } 
      for(var zz=0; zz<this.monthly_selected.length; zz++){
       // console.log(this.all_data[this.monthly_selected[zz]])
        this.all_data[this.monthly_selected[zz]].value.params.month=this.selected_month
        var data=await axios_django.get(this.all_data[this.monthly_selected[zz]].value.api,{params:this.all_data[this.monthly_selected[zz]].value.params})
       // console.log(data)
        this.all_data[this.monthly_selected[zz]].data=data.data
      }
      //this.secondMapData[0].data=data.data
     
      /*
      axios_django.get(value.api,{params:value.params}).then(res=>{

      })*/
    },
    getDataAll(){
      var v=this
      v.load_concentrations=true
      axios_django.get('pespka/type/',{params:{year:this.selected_year,grid:this.selected_grid,type:this.selected_type}}).then(res=>{
      console.log('no2:',res)
      v.no2AllData=res.data
      v.load_concentrations=false

     

      v.current_legend=v.legend_data[v.selected_grid]
      console.log(v.current_legend)
    })
    },
    transformDataStations(num){
      //var name
      //var dataArray = new Array(11).fill(null);
      var dataObj={}
      var index
      var years=[]
      console.log(num)
      dataObj={}
      years=[]
      for(var ff in num){
        //console.log(ff)
        //if(this.stationsAllData[ff].Average_PM25)
        
        //for(var gg in num[ff].Average_PM25){
          index=num[ff].date_val.split('-')
          if(years.includes(index[0])){
            dataObj[index[0]][parseInt(index[1])-1]=num[ff].average_val
          }else{
            years.push(index[0])
            dataObj[index[0]]=new Array(12).fill(null);
            dataObj[index[0]][parseInt(index[1])-1]=num[ff].average_val
          }
          
          
          
          
        //}
        
        
      }
      console.log(dataObj)
      var value={}
      value['transformData']=[]
        for(var rr in dataObj){
         // console.log(rr)
        
         value['transformData'].push({
            name:rr,
            data:dataObj[rr]
         })
       }
       
      return value
      //console.log(this.all_data['stationsAllData']['data'])
    },
    changeGust(){
      var lineChartsBar=this.$refs.lineChartsBar
        if(lineChartsBar){
          lineChartsBar.removeSeries()

        }
      
        lineChartsBar.chart.xAxis[0].categories=this.years;
        
        //this.selected_gust='pm25'
        for(var vv=0; vv<this.chartDataBar.length; vv++){
          lineChartsBar.addSeries({
                  name: this.months[this.chartDataBar[vv].name-1],  
                  data: this.chartDataBar[vv][this.selected_gust]
          })
        }
    },
   async onclickStation(station,name){
      
      if(name==='ydpar_sensors'){
        console.log(station)
        console.log(name)
       var value= await axios_django('pespka/diktia/ydpar_sensors/measurements/',{params:{site_code:station.site_code}})
       value=value.data
       console.log(value)
        this.chartDataBar=[]
       this.years=[]
        for(var nn=0; nn<value.length; nn++){
          if(!this.years.includes(value[nn].mes_year)){
            this.years.push(value[nn].mes_year)
          }
          if(this.chartDataBar[value[nn].mes_month-1]){

            if(value[nn].mes_month===this.chartDataBar[value[nn].mes_month-1].name){
              this.chartDataBar[value[nn].mes_month-1]['no2'].push(value[nn]['no2'])
              this.chartDataBar[value[nn].mes_month-1]['pm10'].push(value[nn]['pm10'])
              this.chartDataBar[value[nn].mes_month-1]['o3'].push(value[nn]['o3'])
              this.chartDataBar[value[nn].mes_month-1]['pm25'].push(value[nn]['pm25'])
            }else{
              this.chartDataBar.push({name:value[nn].mes_month,no2:[value[nn]['no2']],pm10:[value[nn]['pm10']],o3:[value[nn]['o3']],pm25:[value[nn]['pm25']]})

            }
          }else{
            this.chartDataBar.push({name:value[nn].mes_month,no2:[value[nn]['no2']],pm10:[value[nn]['pm10']],o3:[value[nn]['o3']],pm25:[value[nn]['pm25']]})
            //console.log(this.chartDataBar)
          }
          
        }
        //console.log(this.chartDataBar)
        this.selected_gust='pm25'
        this.changeGust()
       
        this.showChartBar='visible'
        
      }else if(name==='stationsAllData'){ 

        
        this.markerActive=[station.latitude,station.longitude]
        console.log(station)
        var average_pm25_value= await axios_django('pespka/diktia/average_pm25/',{params:{name:station.name}})
        console.log(average_pm25_value.data)
        var average_pm25_to_chart=await this.transformDataStations(average_pm25_value.data)
        console.log(average_pm25_to_chart)
        var lineCharts=this.$refs.lineCharts
        if(lineCharts){
          lineCharts.removeSeries()

        }

        for(var zz in average_pm25_to_chart.transformData){
          lineCharts.addSeries({
                  name: average_pm25_to_chart.transformData[zz].name,  
                  data: average_pm25_to_chart.transformData[zz].data
                })
        }
        this.showChart='visible'
      }
    },
    colorsFunc(dn,legend){
      //console.log(legend)
      //console.log(dn)
      
      var  weight = legend.weight ? legend.weight : 0.2;
      var  opacity = legend.opacity ? legend.opacity : 0.9;
      var  weight_color = legend.weight_color ? legend.weight_color : 'black';
      var  fillColor = legend.color ? legend.color : 'black';
      
      if(dn!==undefined){
        if(typeof dn==='number'){
          for(var gg=0; gg<legend.value.length; gg++){
          //console.log(legend[gg].value[0])

            if( legend.value[gg][0] <= dn && dn < legend.value[gg][1]){
              return {'color':weight_color,'fillColor':legend.color[gg],'weight':weight,'fillOpacity':opacity}
            }
          }
        }
        
        if(typeof dn==='string'){
          //console.log(legend.text.indexOf(dn))
          
          var cl=legend.color[legend.text.indexOf(dn)]==='#a7adf2'? '#a7adf2':'#c96666'
          cl=cl.split('#')[1]
          if(legend.polygon_fill_pattern){
            return {'color':weight_color,'fillColor':legend.color[legend.text.indexOf(dn)],'weight':weight,'fillOpacity':opacity,'fill':'url(../img/hatch_'+cl+'.svg)'}
          }else{
            return {'color':weight_color,'fillColor':legend.color[legend.text.indexOf(dn)],'weight':weight,'fillOpacity':opacity}
          }
          //return {'color':weight_color,'fill':'url(./img/hatch.svg)','fillColor':'white','fillOpacity':1}
          //return {'color':weight_color,'fillColor':legend.color[legend.text.indexOf(dn)],'weight':weight,'fillOpacity':opacity,'fill':'url(http://localhost:8080/img/hatch_'+cl+'.svg)'}
          
        }
      }else{
        return {'color':fillColor,'fillColor':fillColor,'weight':weight,'fillOpacity':opacity}
      }
      //console.log(dn)
     /* if(this.selected_grid==='no2'){

      
      if(dn<20){
        return {'color':'#cbd69c','fillColor':'#cbd69c','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=20 && dn<30){
        return {'color':'#dae4a8','fillColor':'#dae4a8','weight':0.2,'fillOpacity':0.9}
      }
      else if(dn>=30 && dn<40){
        return {'color':'#e8ecaf','fillColor':'#e8ecaf','weight':0.2,'fillOpacity':0.9}
      }
      else if(dn>=40 && dn<50){
        return {'color':'#f8fabb','fillColor':'#f8fabb','weight':0.2,'fillOpacity':0.9}
      }
      else if(dn>=50 && dn<60){
        return {'color':'#ffe8a8','fillColor':'#ffe8a8','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=60 && dn<70){
        return {'color':'#f2b889','fillColor':'#f2b889','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=70 && dn<75){
        return {'color':'#ff824c','fillColor':'#ff824c','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=74 && dn<80){
        return {'color':'#f65028','fillColor':'#f65028','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=80){
        return {'color':'#e60000','fillColor':'#e60000','weight':0.2,'fillOpacity':0.9}
      }
    }else if(this.selected_grid==='pm25'){
      
      if(dn<10){
        return {'color':'#cbd69c','fillColor':'#cbd69c','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=10 && dn<15){
        return {'color':'#dae4a8','fillColor':'#dae4a8','weight':0.2,'fillOpacity':0.9}
      }
      else if(dn>=15 && dn<20){
        return {'color':'#e8ecaf','fillColor':'#e8ecaf','weight':0.2,'fillOpacity':0.9}
      }
      else if(dn>=20 && dn<25){
        return {'color':'#f8fabb','fillColor':'#f8fabb','weight':0.2,'fillOpacity':0.9}
      }
      else if(dn>=25 && dn<30){
        return {'color':'#ffe8a8','fillColor':'#ffe8a8','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=30 && dn<35){
        return {'color':'#ff824c','fillColor':'#ff824c','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=35 && dn<40){
        return {'color':'#f65028','fillColor':'#f65028','weight':0.2,'fillOpacity':0.9}
      }else if(dn>=40){
        return {'color':'#e60000','fillColor':'#e60000','weight':0.2,'fillOpacity':0.9}
      }
    }*/
    }
  }
 

};
</script>

<style >
@import "../../src/assets/color_icon.css";
@import "~leaflet/dist/leaflet.css";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
.tree-default .tree-node {
    margin-left: 10px!important;
}
.tree-default .tree-wholerow-clicked {
    background: '#fff0'!important;
}
.tree-default .tree-disabled > .tree-icon {
   opacity: 1!important;
  filter: 0 !important;
  filter: rgba(128, 128, 128, 0) !important;
  -webkit-filter: grayscale(0%) !important;
}
.tree-default .tree-disabled {
    color: rgb(255, 255, 255)!important;
}
.tree-default .tree-disabled > .tree-checkbox {
  opacity: 0.05!important;
}
.tree-default .tree-wholerow-clicked {
    background: #fff0!important;
}
.tree-wholerow-hovered{
  background: #fff0!important;
}
.bottomleft-control {
  background: #6c757d;
    border-radius: 0 20px 0 0;
    box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
}
.logo-perifereia {
  width: 20vh;
}
.chart-div{
  border-radius: 20px 0 0 0;
  box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
  width:35%;
}
.btn-settings{
  border-radius: 0 20px 20px 0!important;
  box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
}
.legend-div{
  position:absolute;
  bottom:8px;
  right:5px;
  z-index: 1001;
}
@media screen and (max-width: 730px) {
  .chart-div{
    border-radius: 0 0 0 0;
    box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
    width:100%;
  }
  .logo-perifereia {
  width: 15vh;
}

.card-body {
   
    padding: 0.4rem!important;
}
}
hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
/*.tree-children{
  padding:10px!important;
}*/

::-webkit-scrollbar {
    width: 10px;
    height:10px
}
::-webkit-scrollbar-thumb {
    background: #07a4ff;
    border-radius: 20px;
}
::-webkit-scrollbar-track {
    background-color: #cbe4f3;
    border-radius: 20px;    
}
.leaflet-control{
  background:#003b5dbf!important;
  border-radius:20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
  padding:10px;
  color:white
}
.leaflet-touch .leaflet-bar a{
  background: #fff0!important;
  color: #fff;

}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: 0px solid rgba(0,0,0,0.2)!important;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
}
.leaflet-touch .leaflet-control-layers-toggle {
    width: 30px;
    height: 30px;
}
.leaflet-control-geosearch a.leaflet-bar-part:after {
    top: 16px;
    left: 16px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
}
.leaflet-control-geosearch a.leaflet-bar-part:before {
    top: 29px;
    left: 26px;
    width: 8px;
    border-top: 2px solid #fff;
    transform: rotateZ(45deg);
    
}
.fa-map-marker{
  font-size:20px;
  color:#fff
}
.leaflet-tooltip-own{
  background:#ffffff30;
  border: 0px solid #ffffff30;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
    }

.geosearch{
  color:black;
  z-index:9000;
}
.col-lg-12{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-6{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-7{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-5{
  padding-right: 0px!important;
  padding-left: 0px!important;
}

</style>



<!--
  {
                "text": "M.A. θερμών ημερών 1981-2000",
                "description":"Μέσος αριθμός πολύ θερμών ημερών (>35°C) την περίοδο αναφοράς (1981-2000)",
                "type_of_data":"geojson",
                "loaded":false,
                "data_column":"Be46",
                "value":[{"api":"pespka/klimatikes_allages/","params": {"db_name":"be46"}}],
                "legend":{"tooltip":{"show":true,"tooltip_column":"mean","permanent":true},"column":"mean", "value":[[0,114],[114,117],[117,124],[124,130],[130,132]],"color":["#ffcccc","#ff9e8f","#fa725a","#ed432d","#db0000"],"text":["0-113","114-117","118-124","125-130","131-132"],"title":"Μέσος αριθμός πολύ θερμών ημερών (>35°C) την περίοδο αναφοράς (1981-2000)","weight":0.5,"weight_color":"black"}
            },
-->